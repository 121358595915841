import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import CustomersSelect from '../../project/CustomersSelect';
import LanguageSelect from '../../project/LanguageSelect';
import AccessLevelSelect from '../../project/AccessLevelSelect';
import AddressSelect from '../../project/AddressSelect';
import {UserDetail, UserAddress} from '../../../constants/propTypesDefinitions';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import {RIGHTS} from '../../../constants/Rights';
import {merge} from '../../../lib/object';
import {createFetchUserEdit} from '../../../backend/apiCalls';
import {Button, Form, Input, Radio} from 'antd';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../constants/form';
import {Trans, t} from '@lingui/macro';
import SupervisorsSelect from '../../project/SupervisorsSelect';
import InputTextArea from '../../general/InputTextArea';
import DepartmentsSelect from '../../project/DepartmentsSelect';
import DashboardItemsSelect from '../../project/DashboardItemsSelect';
import DatePicker from '../../general/DatePicker';
const FormItem = Form.Item;

/**
 * @fero
 */

class EditUserDetail extends React.PureComponent {
    static propTypes = {
        userDetail: UserDetail.isRequired,
        userAddresses: PropTypes.arrayOf(UserAddress.isRequired).isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {userDetail, userAddresses} = this.props;
        const reloadData = this.props[GLOBAL_DATA.RELOAD_DATA];
        return <FormModal
            openNode={
                <Button icon="edit">
                    <span className="ml-2"><Trans>Úprava kontaktu</Trans></span>
                </Button>
            }
            values={{userDetail, userAddresses}}
            onFinishSuccessful={() => {
                reloadData([GLOBAL_DATA.USER_DETAILS, GLOBAL_DATA.USER_DETAILS_ADDRESSES, GLOBAL_DATA.EMPLOYEES])
            }}
            title={<Trans>Úprava kontaktu</Trans>}
            Form={EditUserDetailFormWrapped}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť kontakt.`)}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(EditUserDetail);

class EditUserDetailForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            userDetail: UserDetail,
            userAddresses: PropTypes.arrayOf(UserAddress.isRequired).isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            accessLevel: props.values != null && props.values.userDetail != null ? props.values.userDetail.access_level : undefined,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0);
    }

    onRightsChange = (newRights) => {
        this.setState({accessLevel: newRights});
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        const {userDetail = {}} = values;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const fetchArgs = merge(formValues, {id: userDetail.id});
                createFetchUserEdit()(fetchArgs).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading, accessLevel} = this.state;
        const {onClose, values} = this.props;
        const {userDetail = {}, userAddresses} = values;
        const {getFieldDecorator} = this.props.form;
        const isAssigned = (userDetail != null && userDetail.is_assigned > 0);
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Titul</Trans>}
            >
                {getFieldDecorator('title', {initialValue: (userDetail != null ? userDetail.title : '')})(
                    <Input ref={node => this.first = node}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Meno</Trans>}
            >
                {getFieldDecorator('name', {rules: [mandatoryRule], initialValue: (userDetail != null ? userDetail.name : '')})(
                    <Input/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Priezvisko</Trans>}
            >
                {getFieldDecorator('surname', {rules: [mandatoryRule], initialValue: (userDetail != null ? userDetail.surname : '')})(
                    <Input/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Email</Trans>}
            >
                {getFieldDecorator('e_mail', {initialValue: (userDetail != null ? userDetail.e_mail : '')})(
                    <Input/>
                )}
            </FormItem>
            <RightsWrapper from={RIGHTS.SUPERVISOR}>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Nové heslo</Trans>}
                >
                    {getFieldDecorator('password', {})(
                        <Input 
                            type="password" 
                            autoComplete="new-password"
                            placeholder="nezmenené"
                        />
                    )}
                </FormItem>
            </RightsWrapper>
            <FormItem
                {...formItemLayout}
                label={<Trans>Telefón</Trans>}
            >
                {getFieldDecorator('phone', {initialValue: (userDetail != null ? userDetail.phone : '')})(
                    <Input/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Jazyk</Trans>}
            >
                {getFieldDecorator('id_language', {initialValue: (userDetail != null ? userDetail.id_language : null)})(
                    <LanguageSelect/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Rola</Trans>}
            >
                {getFieldDecorator('access_level', {
                    rules: [mandatoryRule], 
                    initialValue: (userDetail != null ? userDetail.access_level : 1),
                    onChange: this.onRightsChange,
                })(
                    <AccessLevelSelect/>
                )}
            </FormItem>
            { accessLevel >= RIGHTS.MARKETING ? 
                <RightsWrapper from={RIGHTS.MANAGER}>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>Zamestnanec</Trans>}
                    >
                        {getFieldDecorator('is_employee', {initialValue: userDetail != null ? userDetail.is_employee : 1})(
                            <Radio.Group>
                                <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                                <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                            </Radio.Group>
                        )}
                    </FormItem>
                </RightsWrapper>
                : null
            }
            <FormItem
                {...formItemLayout}
                label={<Trans>Organizácia</Trans>}
            >
                {getFieldDecorator('id_customer', {rules: [mandatoryRule], initialValue: (userDetail != null ? userDetail.id_customer : undefined)})(
                    <CustomersSelect
                        className=""
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Spoločnosť</Trans>}
            >
                {getFieldDecorator('organization', {initialValue: (userDetail != null ? userDetail.organization : null)})(
                    <Input disabled={isAssigned}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>IČO</Trans>}
            >
                {getFieldDecorator('ico', {initialValue: (userDetail != null ? userDetail.ico : null)})(
                    <Input disabled={isAssigned}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>IČ DPH</Trans>}
            >
                {getFieldDecorator('ic_dph', {initialValue: (userDetail != null ? userDetail.ic_dph : null)})(
                    <Input disabled={isAssigned}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Pracovná pozícia</Trans>}
            >
                {getFieldDecorator('position', {initialValue: (userDetail != null ? userDetail.position : null)})(
                    <Input/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Odber noviniek</Trans>}
            >
                {getFieldDecorator('newsletter', {initialValue: userDetail != null ? userDetail.newsletter : 0})(
                    <Radio.Group>
                        <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                        <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Poznámky</Trans>}
            >
                {getFieldDecorator('notices', {initialValue: (userDetail != null ? userDetail.notices : null)})(
                    <InputTextArea/>
                )}
            </FormItem>
            <RightsWrapper from={RIGHTS.SUPERVISOR}>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Nadriadený</Trans>}
                >
                    {getFieldDecorator('id_supervisor', {initialValue: (userDetail != null ? userDetail.id_supervisor : null)})(
                        <SupervisorsSelect/>
                    )}
                </FormItem>
            </RightsWrapper>
            <RightsWrapper from={RIGHTS.SUPERVISOR}>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Oddelenie</Trans>}
                >
                    {getFieldDecorator('id_department', {initialValue: (userDetail != null ? userDetail.id_department : null)})(
                        <DepartmentsSelect
                            allowClear={true}
                        />
                    )}
                </FormItem>
            </RightsWrapper>
            <FormItem
                {...formItemLayout}
                label={<Trans>Fakturačná adresa</Trans>}
            >
                {getFieldDecorator('id_invoice_address', {initialValue: userDetail.invoice_address ? userDetail.invoice_address.id : undefined})(
                    <AddressSelect 
                        addresses={userAddresses} 
                        currentOption={userDetail.invoice_address}
                        disabled={isAssigned}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Dodacia adresa</Trans>}
            >
                {getFieldDecorator('id_delivery_address', {initialValue: userDetail.delivery_address ? userDetail.delivery_address.id : undefined})(
                    <AddressSelect 
                        addresses={userAddresses}
                        currentOption={userDetail.delivery_address}
                    />
                )}
            </FormItem>
            <RightsWrapper from={RIGHTS.MANAGER}>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Štatistiky</Trans>}
                >
                    {getFieldDecorator('dashboard_items', {initialValue: userDetail.dashboard_items != null ? userDetail.dashboard_items.map(it => it.variable) : null})
                    (
                        <DashboardItemsSelect
                            isMulti={true}
                            allowClear={true}
                        />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Nový PIN</Trans>}
                >
                    {getFieldDecorator('pin', {})(
                        <Input 
                            type="password" 
                            autoComplete="new-password" 
                            minLength={4} 
                            maxLength={4}
                            placeholder="nezmenený"
                        />
                    )}
                </FormItem>
            </RightsWrapper>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    <Trans>Uložiť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const EditUserDetailFormWrapped = Form.create()(EditUserDetailForm);